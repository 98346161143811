@charset "UTF-8";
.print-background {
  background-color: white !important; }

.narrow-column {
  width: 1.5cm; }

.wide-column {
  min-width: 4cm; }

.double-wide-column {
  min-width: 8cm; }

.print-portrait {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  margin: 0;
  padding: 0; }
  .print-portrait html,
  .print-portrait body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden; }
  @media print {
    .print-portrait {
      size: portrait; }
      .print-portrait .page {
        margin: 0;
        padding: 0;
        position: relative;
        width: 100%;
        height: 100vh; }
      .print-portrait .out-border-top-black {
        border-top-color: black; }
      .print-portrait .out-border-middle-black {
        border-left-color: black;
        border-right-color: black;
        border-bottom-color: white; }
      .print-portrait .out-border-middle-left-black {
        border-left-color: black !important;
        border-right-color: white;
        border-bottom-color: white; }
      .print-portrait .out-border-middle-right-black {
        border-right-color: black !important;
        border-left-color: white;
        border-bottom-color: white; }
      .print-portrait .out-border-bottom-white {
        border-bottom-color: white; }
      .print-portrait .out-border-middle-white {
        border-left-color: white;
        border-right-color: white; }
      .print-portrait .out-border-bottom-black {
        border-bottom-color: black !important; }
      .print-portrait .table-header-bold {
        font-weight: bold !important;
        text-align: center !important; }
      .print-portrait .page-break {
        break-after: page; }
      .print-portrait .wide-cell {
        height: 75px; }
      .print-portrait .border-black {
        border-color: #565757; }
      .print-portrait .small-img {
        height: 130px;
        width: 9000px; }
      .print-portrait .small-text {
        font-size: 12px; }
      .print-portrait .fill-grey {
        background-color: #bfbfbf !important; }
      .print-portrait .fill-black {
        background-color: #000000 !important;
        color: #FFFFFF !important; }
      .print-portrait .cell-header {
        font-weight: bold;
        background-color: #999999 !important; }
      .print-portrait .table-title {
        height: 78px;
        position: relative;
        font-weight: bold;
        font-size: large;
        text-align: center;
        padding-right: 32px;
        vertical-align: middle; }
        .print-portrait .table-title img {
          height: 65px;
          width: auto;
          position: absolute;
          top: 8px;
          left: 8px; }
      .print-portrait .table-sub-title {
        position: relative;
        font-weight: bold;
        font-size: large;
        text-align: center;
        vertical-align: middle; }
      .print-portrait .instructions {
        padding-top: 0.8cm;
        padding-left: 0.8cm;
        font-size: larger;
        line-height: 1.5em;
        height: 250px; }
      .print-portrait .comments {
        height: 180px; }
      .print-portrait .vehicle-image img {
        width: 100%;
        height: auto; }
      .print-portrait .sign-box {
        line-height: 70px; }
      .print-portrait .terms {
        line-height: 1.7em;
        text-align: center;
        font-size: smaller;
        font-weight: bold; } }

@media print {
  .print-landscape {
    size: landscape; }
    .print-landscape html,
    .print-landscape body {
      height: 100vh;
      margin: 0 !important;
      padding: 0 !important;
      overflow: hidden; }
    .print-landscape .job-card-print-title {
      font-size: large;
      margin-bottom: 10px; }
    .print-landscape td {
      font-size: 11px; }
    .print-landscape .header-row {
      font-weight: bold; }
    .print-landscape .terms {
      font-size: x-small; }
    .print-landscape .fill-remaining {
      display: flex;
      flex: 1; }
    .print-landscape .page-break {
      page-break-after: always;
      height: 0;
      display: block;
      clear: both; }
    .print-landscape .notes-headings {
      flex: 1;
      -webkit-flex: 1;
      /* Safari */
      -ms-flex: 1;
      /* IE 10 */ }
    .print-landscape .notes-column {
      display: flex;
      flex-direction: column;
      font-size: x-large;
      padding: 10px;
      margin: 10px;
      -webkit-flex: 1;
      /* Safari */
      -ms-flex: 1;
      /* IE 10 */
      flex: 1;
      /* Standard syntax */ }
    .print-landscape .notes-box {
      border: 2px solid black;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex: 1;
      /* Standard syntax */
      -webkit-flex: 1;
      /* Safari */
      -ms-flex: 1;
      /* IE 10 */ }
    .print-landscape .notes-item {
      flex: 1 0 80%;
      /* Standard syntax */
      -webkit-flex: 1 0 80%;
      /* Safari */
      border: 1px dashed black; }
    .print-landscape .notes-item-header {
      align-items: center;
      text-align: center;
      flex: 1 0 80%;
      /* Standard syntax */
      -webkit-flex: 1 0 80%;
      /* Safari */
      border: 1px dashed black; }
      .print-landscape .notes-item-header h4 {
        height: 0.5cm; }
    .print-landscape .notes-cost-header {
      border: 1px dashed black;
      align-items: center;
      text-align: center;
      flex: 1 0 20%;
      /* Standard syntax */
      -webkit-flex: 1 0 20%;
      /* Safari */ }
      .print-landscape .notes-cost-header h4 {
        height: 0.5cm; }
    .print-landscape .notes-item-full {
      flex: 1 0 100%;
      /* Standard syntax */
      -webkit-flex: 1 0 100%;
      /* Safari */
      border: 1px dashed black; }
    .print-landscape .notes-cost {
      align-items: center;
      flex: 1 0 20%;
      /* Standard syntax */
      -webkit-flex: 1 0 20%;
      /* Safari */
      border: 1px dashed black; }
    .print-landscape .table-print-highlight {
      background-color: lightgray !important; } }

@media print {
  html {
    height: 100vh;
    padding: 1cm !important;
    overflow: hidden; }
  body {
    background-color: white !important;
    height: 75%; }
  table {
    page-break-after: avoid; }
  thead {
    display: table-header-group; }
  .column {
    page-break-inside: avoid; }
  .hide-print {
    display: none; }
  .address-table {
    display: block !important; } }

.bold {
  font-weight: bold !important;
  font-size: large !important; }

.small-figure {
  font-size: 11px; }

.extra-max-width {
  width: 117%;
  max-width: 117%; }

.top-summary {
  position: fixed;
  width: 98%;
  z-index: 27;
  top: 52px; }

.top-spacing {
  padding-top: 20em; }

.button-spacing {
  margin-top: 23px; }

.finance-title {
  font-size: 1.75rem; }

.small-typeahead {
  width: 6rem !important; }

.small-column {
  column-width: 6rem !important; }

.top-summary-section {
  border-right: 1px solid lightgrey; }

.wider-modal {
  width: 70%; }

.resizeable {
  resize: both;
  overflow: auto; }

.box-border-red {
  border: 2px solid red; }

.scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px; }

.box-border-amber {
  border: 2px solid orange; }

.box-border-green {
  border: 2px solid lightgreen; }

.part-search-content {
  width: 80%; }

.page {
  height: 95%; }

.table-checkbox {
  text-align: center !important; }

.currency::before {
  content: "£"; }

.section {
  padding: 1.5rem 0.75rem; }

.rbc-pound-sign {
  padding-bottom: 1px;
  color: darkgrey; }

.colored {
  background-color: #faf7f7; }

.rbc-pound-sign:before {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  content: "£"; }

.small-row-black {
  font-size: small !important;
  color: black !important; }

.min-column-width {
  min-width: 115px; }

.double-wide-textarea {
  min-width: 34em !important; }

.small-row-white {
  font-size: small !important;
  color: white !important; }

.small-icon {
  width: 1.55em !important;
  height: 1.55em !important; }

.small-row button {
  font-size: small !important; }

.address-table {
  display: none;
  font-size: 11px; }

.settings_type {
  margin-top: 24px; }

html {
  height: 100%; }

body {
  background-color: #cfcfcf;
  min-height: 100%; }

a {
  color: black;
  text-decoration: none; }

.no-underline {
  text-decoration: none; }

.date-cell {
  width: 75%; }

.custom-container {
  max-width: 99% !important;
  margin: 0px !important; }

.rental-tile {
  border-radius: 12px;
  background-color: #cfcfcf;
  padding: 1.25em; }

.rental-homepage-tile {
  border-radius: 12px;
  background-color: #cfcfcf;
  padding: 0.35em; }

.registration-homepage {
  padding: 0.5em !important;
  font-size: 12px !important; }

.App {
  text-align: center; }

.select select[disabled] {
  color: black !important; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px; }

.App-header {
  background-color: #dee6e6;
  height: 150px;
  padding: 20px;
  color: white; }

.App-intro {
  font-size: large; }

.typeahead {
  width: 8rem; }
  .typeahead .rbt-input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .typeahead .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 15rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem; }
  .typeahead .rbt-aux {
    align-items: center;
    display: flex;
    bottom: 0;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 34px; }
  .typeahead .rbt-aux .rbt-close {
    margin-top: -4px;
    pointer-events: auto; }
    .typeahead .rbt-aux .rbt-close span {
      font-size: x-large; }
  .typeahead .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5; }
  .typeahead button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  .typeahead .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0; }
  .typeahead .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: black !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

::placeholder {
  color: black !important;
  opacity: 1; }

.registration-ui .dropdown-menu {
  background: linear-gradient(to bottom, #f8d038 0%, #f5ca2e 100%);
  width: 10%;
  padding: 0.25em 1em 0.25em 1.75em;
  font-weight: bold;
  font-family: helvetica, ariel, sans-serif;
  border-radius: 5px;
  border: 1px solid #000; }

.registration-ui .rbt-input-main {
  background: linear-gradient(to bottom, #f8d038 0%, #f5ca2e 100%);
  padding: 0.25em 1em 0.25em 1.75em;
  font-weight: bold;
  font-size: 2em;
  border-radius: 5px;
  border: 1px solid #000;
  box-shadow: 1px 1px 1px #ddd;
  position: relative;
  font-family: helvetica, ariel, sans-serif; }

.vehicle-finder div input {
  width: 100%; }

.registration-wrapper:before {
  content: "GB";
  display: block;
  width: 30px;
  height: 100%;
  background: #063298;
  position: absolute;
  top: 0;
  border-radius: 5px 0 0 5px;
  color: #f8d038;
  font-size: 0.5em;
  line-height: 85px;
  padding-left: 5px; }

.registration-wrapper:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 5px;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  border: 1px dashed #f8d038; }

.reg-wrapper {
  width: 24%; }

.registration-field {
  background: linear-gradient(to bottom, #f8d038 0%, #f5ca2e 100%);
  padding: 0.25em 1em 0.25em 1.25em;
  text-align: center;
  font-weight: bold;
  max-width: 180px;
  font-size: 1.25em;
  border-radius: 5px;
  border: 1px solid #000;
  box-shadow: 1px 1px 1px #ddd;
  position: relative;
  font-family: helvetica, ariel, sans-serif; }

.scrollable-panel {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  height: 350px; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.Collapsible {
  background-color: white; }

.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px; }

.Collapsible__contentInner p:last-child {
  margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid white;
  padding: 10px; }

.Collapsible__trigger:after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  border: solid black;
  content: " ";
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: transform 300ms; }

.Collapsible__trigger.is-open:after {
  transform: rotateZ(45deg); }

.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey; }

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; }

.CustomTriggerCSS--open {
  background-color: darkslateblue; }

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black; }

.rental-column {
  border-width: 0 1px 0 1px;
  border-style: ridge; }

#overlay {
  position: fixed;
  /* Sit on top of the page content */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  padding-top: 20%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
  text-align: center; }

.clickable:hover {
  cursor: pointer; }

.currencyinput {
  border: 1px inset #ccc; }

.spinner-centre {
  text-align: center; }

.react-spinner-material {
  display: inline-block;
  border-radius: 50%;
  border: 10px solid #333;
  margin: auto;
  box-sizing: border-box;
  -webkit-animation: rsm-sweep 1s linear alternate infinite, rsm-rotate 0.8s linear infinite;
  animation: rsm-sweep 1s linear alternate infinite, rsm-rotate 0.8s linear infinite; }

.active-tab {
  background-color: #e78023;
  color: white; }
  .active-tab:hover {
    background-color: #e78023 !important;
    color: white !important; }

.table-highlight-orange {
  background-color: #e78023 !important;
  color: white; }
  .table-highlight-orange:hover {
    background-color: #e78023 !important; }

.table-highlight-red {
  background-color: red !important;
  color: white !important; }
  .table-highlight-red:hover {
    background-color: red !important; }

.table-highlight-dark-red {
  background-color: #cc1b1b !important;
  color: white !important; }
  .table-highlight-dark-red:hover {
    background-color: #cc1b1b !important; }

.table-highlight-dark-gray {
  background-color: black; }

.table-highlight-green {
  background-color: lightgreen !important;
  color: white; }
  .table-highlight-green:hover {
    background-color: lightgreen !important; }

div .message {
  background-color: transparent; }

.comments-remove-button {
  width: 25px; }

.comments-wrapper {
  width: 95%; }

.small-icon {
  padding-top: 12px;
  color: #5f5f5f !important; }

.second-delete {
  display: flex;
  padding-bottom: 15px; }

.second-delete-button {
  margin-left: auto; }

.small-row-black::-webkit-outer-spin-button,
.small-row-black::-webkit-inner-spin-button {
  -webkit-appearance: none; }

.ws-instructions {
  background-color: yellow !important;
  color: black !important; }

.table-highlight-yellow {
  background-color: yellow !important;
  color: white; }
